import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from "../views/Main.vue";
import Auth from "../views/Auth.vue";
import beforeEach from './guards.js'

Vue.use(VueRouter);

const routes = [
  { path: '/', component: Main },
  { path: '/auth', component: Auth }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach(beforeEach)

export default router;