<template>
  <v-app>
    <v-main class="main">
      <NavBar />
      <router-view></router-view>
    </v-main>
    <footer class="footer text-center py-2">
      <span class="footer-text font-weight-light"
        >Go Mobile © {{ new Date().getFullYear() }}</span
      >
    </footer>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";

export default {
  components: {
    NavBar
  }
}


</script>
<style lang="scss">
.ul {
  list-style: none;
}
.main {
  background-color: #f5f5f5;
}
.heading {
  font-size: 25px !important;
  color: #666666;
}

.footer {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(206, 215, 224, 0.3);
  .footer-text {
    font-size: 15px;
  }
}
.avalible {
  color: green;
}
.not-avalible {
  color: red;
}
</style>