import axios from "axios";
// import MockAdapter from "axios-mock-adapter";

export default class BaseApi {
  client;

  constructor(path, host = "/") {
    this.client = axios.create({
      baseURL: host + path,
    });

    // const mock = new MockAdapter(this.client, { onNoMatch: "throwException" });

    // mock.onGet('/devices').reply(() => {
    //   return [200, [{"id":3,"description":"gotech","phone_number":"+79151925422 / Go Tech"},{"id":1,"description":"android app","phone_number":"+79259367332 и +79969709882"},{"id":2,"description":"android app sim 2","phone_number":"не активен"}]]
    // })

  }

  insertTokenOnRequest(
    config,
    token
  ) {
    config.headers.Authorization = token;
    return config;
  }
}
