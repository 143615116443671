import BaseApi from "./baseApi";
import { identityService } from "../services";

export default class AuthedApi extends BaseApi {
  constructor() {
    super("/");
    this.client.interceptors.request.use(
      (config) => {
        const token = identityService.getAccessToken();
        // const token = 'sdsd';
        if (!token) return Promise.reject("No token provided");
        config.headers.setAuthorization(token);
        return config;
      }
    );

    this.client.interceptors.response.use(
      (response) => {
        if (response.data == 'unauthorized') {
          identityService.logout();
          return response;
        }
        return response;
      },
      (error) => {
        if (error.response) {
          if (error.response.status === 401) {
            identityService.logout();
          }
        }
        return Promise.reject(error);
      }
    );
  }
}
