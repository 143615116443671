import { identityApi } from "../api";
import{ jwtDecode } from "jwt-decode";
import router from "../router";
// import RouterNames from "@/router/names";

class TokenPair {
  accessToken = "";
  refreshToken = "";
  accessPayload = null;
  refreshPayload = null;

  constructor(at, rt) {
    this.accessToken = at;
    this.refreshToken = rt;
    this.decodeTokens();
  }

  decodeTokens() {
    this.accessPayload = jwtDecode(this.accessToken);
    this.refreshPayload = jwtDecode(this.refreshToken);
  }

  static fromLocalStorage(unparsed) {
    const parsed = JSON.parse(unparsed);
    const newPair = new TokenPair(parsed.access_token, parsed.refresh_token);
    return newPair.isValid() ? newPair : null;
  }

  static fromResponse(response) {
    const newPair = new TokenPair(response.access_token, response.refresh_token);
    return newPair.isValid() ? newPair : null;
  }

  toString() {
    return JSON.stringify({
      access_token: this.accessToken,
      refresh_token: this.refreshToken,
    });
  }

  isValid() {
    return !!this.accessToken && !!this.refreshToken && !!this.accessPayload && !!this.refreshPayload;
  }

  isAccessTokenExpired() {
    const exp = this.accessPayload?.exp;
    if (!exp) return true;
    return Date.now() >= exp * 1000;
  }

  isRefreshTokenExpired() {
    const exp = this.refreshPayload?.exp;
    if (!exp) return true;
    return Date.now() >= exp * 1000;
  }
}

export default class IdentityService {
  localStorageKey = "token_pairs";
  localStorageKeyMail = "user_email";
  localStorageKeyPhoto = "user_photo";
  checkTokenIntervalMS = 1000;

  isLoggedIn = false;

  tokenPair = null;

  api;

  constructor() {
    this.api = identityApi;
    const possibleToken = localStorage.getItem(this.localStorageKey);
    if (!possibleToken) return;

    this.tokenPair = TokenPair.fromLocalStorage(possibleToken);
    if (this.tokenPair) this.isLoggedIn = true;
    setInterval(this.checkAccessToken, this.checkTokenIntervalMS);
  }

  saveToken(pair) {
    this.tokenPair = pair;
    this.isLoggedIn = true;
    localStorage.setItem(this.localStorageKey, pair.toString());
    localStorage.setItem(this.localStorageKeyMail, this.tokenPair?.accessPayload?.payload.email?.toString() ?? "");
    localStorage.setItem(this.localStorageKeyPhoto, this.tokenPair?.accessPayload?.payload.picture_url ?? "");
  }

  async loginByGoogleToken(googleToken) {
    const response = await this.api.loginByGoogleToken(googleToken);

    const pair = TokenPair.fromResponse(response.data);
    if (!pair) return Promise.reject();

    this.saveToken(pair);
  }

  async refreshToken() {
    if (!this.tokenPair) return Promise.reject("token pair is null");
    if (this.tokenPair.isRefreshTokenExpired()) return Promise.reject("refresh token is expired");
  }

  async logout() {
    localStorage.setItem(this.localStorageKey, "");
    this.tokenPair = null;
    this.isLoggedIn = false;
    if (router.currentRoute.path !== '/auth') {
      await this.goToLogin();
    }
  }

  getAccessToken() {
    return this.tokenPair ? this.tokenPair.accessToken : null;
  }

  getIsAuthorize() {
    return this.isLoggedIn;
  }

  async goToLogin() {
    await router.push("/auth");
  }

  checkAccessToken = async () => {
    if (!this.tokenPair) {
      console.log("token is null");
      return this.logout();
    }
    if (this.tokenPair.isAccessTokenExpired()) {
      return await this.refreshToken().catch(() => {
        console.log("unsuccessful refresh");
        return this.logout();
      });
    }
  };
}
