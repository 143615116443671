<template>
<div class="d-flex align-center">
  <v-container>
    <v-row align="center">
      <v-col class="d-flex pa-10" cols="12" sm="6">
        <v-select
          color="orange"
          :items="phoneNumbers"
          item-text="phone_number"
          item-value="id"
          v-model="selected"
          label="Phone Number"
        ></v-select>
      </v-col>
      <v-col
        ><v-btn
          elevation="2"
          x-large
          color="orange"
          dark
          @click="updateData"
          >UPDATE</v-btn
        ></v-col
      >
      <v-col> Data Freshness: {{ sortMess | moment }} </v-col>
      <v-col> Battery Charge: {{ batteryStatus }} </v-col>
    </v-row>
    <v-row>
      <v-col
        v-show='hasSelected'
        :class='isAvalible ? "avalible" : "not-avalible"'
      >
        {{ isAvalible ? 'Phone is active' : 'Phone is not avalible. Please check it is ok' }}
      </v-col>
    </v-row>
    <v-spacer></v-spacer>

    <v-card class="mt-5">
      <v-tabs v-model="tab" background-color="orange" dark>
        <v-tab
          v-for="(item, index) in items"
          :key="item.tab"
          :class="{ active: currentTab === index }"
          @click="currentTab = index"
        >
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <v-card flat>
            <div v-show="currentTab === 0">
              <ul class="ul pa-0">
                <li
                  class="li pb-5"
                  v-for="(m, index) in sortMessageResponse"
                  :key="index"
                >
                  <v-card>
                    <v-card-title> {{ m.time | moment }}</v-card-title>
                    <v-card-subtitle
                      class="pb-1 text-decoration-underline"
                      v-text="m.origin"
                    ></v-card-subtitle>
                    <v-card-text
                      v-text="
                        decodeURIComponent(
                          (m.message + '').replace(/\+/g, '%20')
                        )
                      "
                    ></v-card-text>
                  </v-card>
                </li>
              </ul>
            </div>
            <div v-show="currentTab === 1">
              <ul class="ul pa-0">
                <li
                  class="pb-5"
                  v-for="(c, index) in sortCallsResponse"
                  :key="index"
                >
                  <v-card>
                    <v-card-text class="pb-1">{{
                      c.time | moment
                    }}</v-card-text>
                    <v-card-title
                      class="pt-0"
                      v-text="c.phone"
                    ></v-card-title>
                  </v-card>
                </li>
              </ul>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</div>
  

</template>

<script>
import moment from "moment";
import { deviceApi } from "../api";

export default {
  components: {

  },
  name: "Main",
  data: () => ({
    loggedIn: false,
    phoneNumbers: [],
    selected: "",
    freshness: "DD/MM/YY",
    battery: null,
    currentTab: 0,
    tab: null,
    items: [
      { tab: "SMS", content: "SMS Content" },
      { tab: "CALLS", content: "Calls Content" },
    ],
    calls: [],
    messages: [],
  }),
  mounted() {
    deviceApi.getDevices().then((data) => { this.phoneNumbers = data });
  },
  filters: {
    moment: function (date) {
      moment.locale("ru");
      return moment(date * 1000).format("lll");
    },
  },
  methods: {
    updateData: function () {
      if (!this.selected) return
      deviceApi.getBattery(this.selected).then((data) => { this.battery = data });
      deviceApi.getCalls(this.selected).then((data) => { this.calls = data });
      deviceApi.getMessages(this.selected).then((data) => { this.messages = data });
    },
  },
  computed: {
    batteryStatus() {
      if (!this.battery) {
        return "no data"
      }
      return this.battery.charge + '%'
    },
    isAvalible() {
      if (!this.battery) {
        return false
      }
      const now = new Date()
      const minDeadTime = now.setTime(now.getTime() - 5 * 60000)
      return (new Date(this.battery.updated_at)).getTime() > minDeadTime
    },
    hasSelected() {
      return !!this.selected
    },
    sortMess() {
      let arr = this.messages;
      arr = arr.concat(this.calls);
      arr.sort((a, b) => (a.time > b.time ? 1 : -1)).reverse();

      let result = arr[0];
      if (result !== undefined) {
        return result.time;
      } else {
        return result;
      }
    },
    sortCallsResponse() {
      const filterdCalls = this.calls;
      return filterdCalls.sort((a, b) => a.time - b.time).reverse();
    },
    sortMessageResponse() {
      const filterdCalls = this.messages;
      return filterdCalls.sort((a, b) => a.time - b.time).reverse();
    },
  },
};
</script>

<style>
</style>