import { identityService } from "../services";

export default function (to, from, next) {
  if (to.path !== '/auth' && !identityService.getIsAuthorize()) {
    next({
      path: '/auth',
    });
  } else if (to.path == '/auth' && identityService.getIsAuthorize()) {
    next({
      path: '/'
    })
  } else {
    next();
  }
}