<template>
  <v-container class="mainWrapper">
    <v-layout>
      <v-flex  justify-space-around
        align-center
        d-flex
        flex-row>
        <!-- <v-img
          max-width="400px"
          width="100%"
          src="../assets/img/logo.png"
          alt="main logo"
        ></v-img> -->
        <v-card max-width="500" class="rounded-xl pa-15 mx-3" elevation="0">
          <v-flex justify-center align-center d-flex flex-column>
            <p class="text-center text-h4 font-weight-bold" color="orange">
              Добро пожаловать <br />
              в Go Message
            </p>
            <v-card-text class="my-7 text-center body-1">
              Пожалуйста, авторизуйтесь через почту gomobile
            </v-card-text>
            <v-card-actions
              class="d-flex flex-column align-center justify-space-between"
            >
              <!-- <GoogleSignInBtn /> -->
            </v-card-actions>
          </v-flex>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
// import GoogleSignInBtn from "../components/GoogleSignInBtn.vue";
export default {
  components: {
    // GoogleSignInBtn,
  },
  data: () => ({
    headerTitle: "Go Message",
  }),
};
</script>

<style lang="scss">
.mainWrapper {
  height: 90%;
  display: flex;
}
</style>../components/GoogleSignInBtn.vue
