<template>
  <div class="sign-in-group">
    <v-tooltip bottom v-if="!identityService.isLoggedIn">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" ref="signingapi" class="btn btn-sign-in ">
          <!-- <span class="mx-auto">Войти</span> -->
        </div>
      </template>
      <span>Не нажимается? Hard reload: ctrl+shift+r</span>
    </v-tooltip>
    <div v-else class="logoutBtn btn-sign-in" @click="logout">
      <img class="googleAva" :src="ava" alt="avatar" />
      <span class="hideMobile">Выйти</span>
    </div>
  </div>
</template>

<script>
// const router = useRouter();
// const route = useRoute();
import { identityService } from '../services';
import Vue from "vue";

export default Vue.extend({
  name: "GoogleSignInBtn",
  data: function () {
    return {
      identityService,
      loggedIn: identityService.isLoggedIn,
      avatar: "",
    };
  },
  computed: {
    ava() {
      const urlava =
        localStorage.getItem("user_photo") ||
        "https://picsum.photos/500/300?image=20";

      return urlava;
    },
  },
  watch: {
    '$route': function() {
      if (this.$router.currentRoute.path === '/auth') {
        setTimeout(() => {
          this.renderButton()
        }, 0)
      }
    } 
  },
  
  mounted() {
    this.renderButton();
  },
  methods: {
    async handleResponse(response) {
      const token = response.credential;
      await identityService
        .loginByGoogleToken(token)
        .then(() => {
          this.loggedIn = true;
          this.$router.push('/');
        })
        .catch((err) => console.error(err));
    },
    async logout() {
      await identityService.logout();
      this.loggedIn = false;
      // this.$router.push("/auth");
    },
    renderButton() {
      const { google } = window;
      const btn = this.$refs.signingapi;
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: this.handleResponse,
      });
      if (btn) {
        google.accounts.id.renderButton(btn, {
          width: 200,
          size: "large",
          shape: "pill",
          theme: "filled_black",
          type: "standard",
          logo_alignment: "left",
          text: "signin_with",
        });
        google.accounts.id.prompt();
      }
    }
  }
});
</script>

<style lang="scss">
.sign-in-group {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
}

.googleAva {
  width: 45px;
  height: 45px;
  margin: 0 5px;
  border-radius: 50%;
}

.logoutBtn {
  font-weight: 300;
  font-size: 25px;
  color: rgb(87, 83, 83);
}

.btn-sign-in {
  display: flex;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 545px) {
  .googleAva {
    width: 40px;
    height: 40px;
  }

  .sign-in-group {
    .btn-sign-in {
      padding: 5px;
    }

    .hideMobile {
      display: none;
    }
  }
}
</style>
