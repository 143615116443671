import BaseApi from "./baseApi";

const identityServerHost = "https://entry.api.goaheadtech.net/";

export default class IdentityApi extends BaseApi {
  constructor() {
    super("api/v1", identityServerHost);
  }

  async loginByGoogleToken(googleToken) {
    return await this.client.post("/google/identity/login", {
      id_token: googleToken,
    });
  }
}
