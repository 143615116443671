<template>
  <div class="navbarGrad text-center py-5">
    <div class="font-weight-light deep-orange--text headline">
      <div class="header-title">{{ headerTitle }}</div>
    </div>
    <div class="siginbtn">
      <GoogleSignInBtn />
    </div>
  </div>
</template>
<script>
import GoogleSignInBtn from "./GoogleSignInBtn.vue";
export default {
  components: {
    GoogleSignInBtn,
  },
  data: () => ({
    headerTitle: "Go Message",
  }),
};
</script>
<style lang="scss">
.navbarGrad {
  box-shadow: 0px 0px 20px rgba(206, 215, 224, 0.3);
  position: relative;
}
.header-title {
  user-select: none;
}
.siginbtn{
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
</style>./GoogleSignInBtn.vue